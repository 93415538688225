import Loading from '@/components/Loading'
import api from '@/services/api'
import { setAuthenticated, setToken, setUser } from '@/store/slices/auth/actions'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

function useQuery() {
  const { search } = useLocation()
  return useMemo(() => new URLSearchParams(search), [search])
}

function RedirectManager() {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    const token = query.get('token') as string
    console.log({ token })
    if (token) {
      api.getCurrentUser({ token }).then((data: any) => {
        console.log({ data })
        dispatch(setUser(data.data))
        dispatch(setAuthenticated(true))
        dispatch(setToken(data.data.token))
        history.push('/')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loading />
    </div>
  )
}

export default RedirectManager
