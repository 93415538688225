import { combineReducers } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
import storage from 'redux-persist/lib/storage'
import { elementsReducer, ElementsState } from './slices/elements/reducer'
import { uploadsReducer } from './slices/uploads/reducer'
import { PersistConfig } from 'redux-persist/es/types'
import { fontsReducer } from './slices/fonts/reducer'
import { templatesReducer } from './slices/templates/reducer'
import { creationsReducer } from './slices/creations/reducer'
import { authReducer } from './slices/auth/reducer'

const elementsPersistConfig: PersistConfig<ElementsState> = {
  key: 'elements',
  storage,
}

const rootReducer = combineReducers({
  editor: combineReducers({
    elements: persistReducer(elementsPersistConfig, elementsReducer),
    uploads: uploadsReducer,
    fonts: fontsReducer,
    templates: templatesReducer,
  }),
  creations: creationsReducer,
  auth: authReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
