import { useEffect } from 'react'
import useAppContext from '@/hooks/useAppContext'
import Editor, { useEditor } from '@scenify/sdk'
import { useParams } from 'react-router'
import { getElements } from '@store/slices/elements/actions'
import { getFonts } from '@store/slices/fonts/actions'
import { useAppDispatch } from '@store/store'
import Navbar from './components/Navbar'
import Panels from './components/Panels'
import Toolbox from './components/Toolbox'
import Footer from './components/Footer'
import api from '@services/api'

function App({ location }) {
  const { setCurrentTemplate } = useAppContext()
  const editor = useEditor()
  const { id } = useParams<{ id: string }>()
  const dispath = useAppDispatch()

  useEffect(() => {
    dispath(getElements())
    dispath(getFonts())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const editorConfig = {
    clipToFrame: true,
    scrollLimit: 0,
  }

  useEffect(() => {
    if (id && editor && location) {
      const locationTemplate = location?.state?.template
      if (locationTemplate) {
        setCurrentTemplate(locationTemplate)
        editor.importFromJSON(locationTemplate)
      } else {
        api.getCreationById(id).then(data => {
          if (data && data.object !== 'error') {
            setCurrentTemplate(data)
            editor.importFromJSON(data)
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, editor, location])

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#F9F9F9',
        fontFamily: 'Plus Jakarta Sans',
      }}
    >
      <Navbar />
      <div style={{ display: 'flex', flex: 1 }}>
        <Panels />
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
          <Toolbox />
          <div style={{ flex: 1, display: 'flex', padding: '1px' }}>
            <Editor config={editorConfig} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default App
