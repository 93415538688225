import { User } from '@/interfaces/app'
import { createAction } from '@reduxjs/toolkit'
// import api from '@services/api'

// Actions

export const setAuthenticated = createAction<boolean>('auth/setAuthenticated')
export const setToken = createAction<string>('auth/setToken')
export const removeAuth = createAction('auth/removeAuth')

export const setUser = createAction<Partial<User>>('auth/setUser')
