function Bold({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4439 10.734C15.858 10.0651 16.0857 9.2977 16.1034 8.51122C16.1211 7.72474 15.9282 6.94783 15.5447 6.26097C15.1611 5.57412 14.6009 5.0023 13.9221 4.60477C13.2433 4.20723 12.4705 3.99843 11.6838 4.00001H6V19.1568H13.5784C14.5847 19.1578 15.5612 18.8153 16.3462 18.1857C17.1313 17.5562 17.6779 16.6775 17.8955 15.695C18.1131 14.7125 17.9887 13.6852 17.5429 12.783C17.0971 11.8809 16.3566 11.158 15.4439 10.734V10.734ZM8.52613 6.52613H11.6838C12.1863 6.52613 12.6682 6.72574 13.0235 7.08105C13.3788 7.43635 13.5784 7.91825 13.5784 8.42073C13.5784 8.92321 13.3788 9.40511 13.0235 9.76041C12.6682 10.1157 12.1863 10.3153 11.6838 10.3153H8.52613V6.52613ZM13.5784 16.6306H8.52613V12.8415H13.5784C14.0809 12.8415 14.5628 13.0411 14.9181 13.3964C15.2734 13.7517 15.473 14.2336 15.473 14.736C15.473 15.2385 15.2734 15.7204 14.9181 16.0757C14.5628 16.431 14.0809 16.6306 13.5784 16.6306V16.6306Z"
        fill="black"
      />
    </svg>
  )
}

export default Bold
