function Signout({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 18.75V5.25C4.5 5.05109 4.57902 4.86032 4.71967 4.71967C4.86032 4.57902 5.05109 4.5 5.25 4.5H9V3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25L3 18.75C3 19.3467 3.23705 19.919 3.65901 20.341C4.08097 20.7629 4.65326 21 5.25 21H9V19.5H5.25C5.05109 19.5 4.86032 19.421 4.71967 19.2803C4.57902 19.1397 4.5 18.9489 4.5 18.75V18.75Z"
        fill="currentColor"
      />
      <path
        d="M20.3422 10.4095L16.9028 6.96997L15.8422 8.03047L19.0402 11.2285L6.75 11.2502V12.7502L19.0823 12.7285L15.8408 15.97L16.9012 17.0305L20.3408 13.591C20.7628 13.1692 21 12.5972 21.0003 12.0005C21.0006 11.4039 20.7639 10.8316 20.3422 10.4095Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Signout
