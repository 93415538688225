import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Editor from '@scenes/Editor'
import Dashboard from '@scenes/Dashboard'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from './store/slices/auth/selectors'
import RedirectManager from '@scenes/RedirectManager'

const Routes = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated)

  if (!isAuthenticated) {
    return (
      <Router>
        <Switch>
          <Route path="/redirect" component={RedirectManager} />
          <Route path="/" component={Editor} />
        </Switch>
      </Router>
    )
  }
  return (
    <Router>
      <Switch>
        <Route path="/design/:id/edit" component={Editor} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </Router>
  )
}

export default Routes
